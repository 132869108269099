import { Store } from 'vuex';
import {
  toggleSnack,
  toggleLoading,
  requestsSentCount,
  toggleNavDrawer,
  updateProgressBar,
  setFeatureFlags,
  setCompanyId,
} from '@/plugins/vuex/mutationTypes';
import modules from '@/plugins/vuex/modules';
import plugins from '@/plugins/vuex/plugins';

const strict = process.env.NODE_ENV === 'development';

function createStore() {
  const store = new Store({
    strict,
    plugins,
    modules,

    // TODO: refactor use modules
    state: {
      loading: false,
      requestsSentCount: 0,
      navDrawer: false,
      snackbar: {
        value: false,
        message: '',
        color: '',
        timeout: 3000,
      },
      progressBar: {
        surveyName: '',
        progress: 0,
      },
      featureflags: [],
      companyId: '',
    },

    actions: {},

    mutations: {
      [requestsSentCount](state, payload) {
        state.requestsSentCount += payload;
      },

      [toggleLoading](state, payload) {
        state.loading = payload;
      },

      [toggleNavDrawer](state, payload) {
        state.navDrawer = payload;
      },

      [toggleSnack](state, payload) {
        const { snackbar } = state;
        snackbar.value = !snackbar.value;
        snackbar.message = payload.message || '';
        snackbar.color = payload.color || '';
      },

      [updateProgressBar](state, { surveyName, progress }) {
        state.progressBar = {
          surveyName,
          progress,
        };
      },
      [setFeatureFlags](state, featureflags) {
        state.featureflags = featureflags;
      },
      [setCompanyId](state, companyId) {
        state.companyId = companyId;
      },
    },

    getters: {
      hasFlag: (state) => (flag) => state.featureFlags.includes(flag),
    },
  });

  return store;
}

export default createStore;
